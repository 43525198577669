.data-grid-action-menu .MuiSvgIcon-root {
  vertical-align: middle;
}
.data-grid-action-menu .MuiList-root .MuiSvgIcon-root {
  font-size: 18px;
  margin-right: 5px;
}
.data-grid-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
.ag-header-cell.text-center .ag-header-cell-label {
  justify-content: center;
}

.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
  padding-left: 10px;
  padding-right: 10px;
}
.ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 9px;
  padding-right: 9px;
}
.ag-row.highlight-row {
  background-color: rgba(228, 18, 53, 0.4);
}